import {Heading, Text} from '@chanzuckerberg/eds';
import {isRouteErrorResponse} from '@remix-run/react';

type Props = {
  error: unknown;
};

/**
 * Error boundary for the root route. It's extracted to its own component so we can more easily
 * write a Storybook story for it that won't also have all Root's script, meta, and link tags.
 *
 * All children routes can also define their own error boundaries, which will "catch" errors for
 * only that part of the routing hiearchry.
 *
 * See https://remix.run/docs/en/main/guides/errors for more info.
 */
export default function RootErrorBoundary({error}: Props) {
  // Error responses from actions and loaders.
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div>
          <Heading preset="headline-lg">Not found 🔍</Heading>
          <Text>We looked everywhere, but couldn't find it.</Text>
        </div>
      );
    }

    return (
      <div>
        <Heading preset="headline-lg">Uh oh, something went wrong 😞</Heading>
        <Text>
          {error.status} {error.statusText}
        </Text>
        <Text>{error.data}</Text>
      </div>
    );
  }

  // Errors thrown at runtime or during rendering.
  if (error instanceof Error) {
    return (
      <div>
        <Heading preset="headline-lg">Uh oh, something went wrong 😞</Heading>
        <Text>Sorry about that. Please contact support.</Text>
        {window.ENV?.NODE_ENV === 'development' && (
          <>
            <Text>{error.message}</Text>
            <pre
              className="border bg-brand-primary-default p-1"
              data-chromatic="ignore"
            >
              <code>{error.stack}</code>
            </pre>
          </>
        )}
      </div>
    );
  }

  // Non-errors that were thrown for some reason.
  return (
    <div>
      <Heading preset="headline-lg">Unknown Error</Heading>
      <Text>Please contact support</Text>
    </div>
  );
}
