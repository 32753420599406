import edsFontsUrl from '@chanzuckerberg/eds/fonts.css';
import edsStylesUrl from '@chanzuckerberg/eds/index.css';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import localTokensUrl from './app-theme.css';
import RootErrorBoundary from '~/components/RootErrorBoundary';
import tailwindBaseStylesheetUrl from '~/tailwind-base.css';
import tailwindStylesheetUrl from '~/tailwind.css';
import useNonce from '~/utils/useNonce';

export const links: LinksFunction = () => {
  return [
  { rel: 'icon', href: '/favicon.png' },
  // Load the Tailwind base before EDS, so its preflight/reset does not clobber EDS styles.
  { rel: 'stylesheet', href: tailwindBaseStylesheetUrl },
  { rel: 'stylesheet', href: edsStylesUrl },
  { rel: 'stylesheet', href: edsFontsUrl },
  // Add in local EDS theme values
  { rel: 'stylesheet', href: localTokensUrl },
  // Load the Tailwind utility classes after EDS, so Tailwind can override EDS styles.
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];

};

export const meta: MetaFunction = () => [
{
  title: 'Lit Booster'
}];


export function loader({ request, context }: LoaderFunctionArgs) {
  const { GIT_SHA, SENTRY_DSN, NODE_ENV } = process.env;

  return json({
    ENV: {
      GIT_SHA,
      SENTRY_DSN,
      NODE_ENV
    }
  });
}

function App() {
  const { ENV } = useLoaderData<typeof loader>();
  const nonce = useNonce();

  return (
    <html className="h-full scroll-smooth" lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content={ENV.GIT_SHA || 'unknown'} name="version" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Outlet />
        <ScrollRestoration nonce={nonce} />
        <script
          dangerouslySetInnerHTML={{
            // Type for ENV are defined in global.d.ts.
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }}
          nonce={nonce} />

        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
      </body>
    </html>);

}

/**
 * Root error boundary that will be shown if other routes don't "catch" an error first.
 *
 * Note that this includes meta, link, and script tags, since the root component won't be rendered
 * if we're showing this. Error boundaries on all nested (AKA child) routes should _not_ include
 * these.
 *
 * See https://remix.run/docs/en/main/guides/errors for more on handling errors in Remix apps.
 */
export function ErrorBoundary() {
  const error = useRouteError();
  const nonce = useNonce();
  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <Meta />
        <Links />
      </head>
      <body>
        <RootErrorBoundary error={error} />
        <Scripts nonce={nonce} />
      </body>
    </html>);

}

export default withSentry(App, {
  errorBoundaryOptions: {
    // Tell Sentry to render our Remix error boundary, in addition to its own. Otherwise Sentry's
    // build in error boundary will override our Remix one's in many cases. Remove if Sentry
    // resolves https://github.com/getsentry/sentry-javascript/issues/5762.
    fallback: ErrorBoundary
  }
});